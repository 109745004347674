import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./User.css";
import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, query, collection, where } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ClickDownload } from "./ClickDownload";

const firebaseConfig = {
  apiKey: "AIzaSyAmDJEkfxZTyU8yrPMEfiPhMqvH_YRLaY4",
  authDomain: "paparazzi-5e851.firebaseapp.com",
  projectId: "paparazzi-5e851",
  storageBucket: "paparazzi-5e851.appspot.com",
  messagingSenderId: "589024454942",
  appId: "1:589024454942:web:c8e9c8a9f90a07cb03810f",
  measurementId: "G-9BD6F47S8L",
};

const app = initializeApp(firebaseConfig);
// Initialize Firebase Analytics
const analytics = getAnalytics(app); // <-- Initialize analytics


const functions = getFunctions(app, "europe-west1");
const db = getFirestore(app);
const storage = getStorage(app);

const User = () => {
  let { userId } = useParams();
  userId = userId.toLowerCase();
  let navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState("");
  const [question, setQuestion] = useState("");
  const [uid, setUid] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(<></>);

  useEffect(() => {
    setLoading(true);
    logEvent(analytics, 'start_get', { userId });
    const querySnapshot = query(collection(db, "users"), where("username", "==", userId));
    getDocs(querySnapshot)
      .then((querySnapshot) => {
        // Check if we received any documents
        if (!querySnapshot.empty) {
          // Assuming usernames are unique, there should only be one document
          const userDoc = querySnapshot.docs[0];
          const userdata = userDoc.data();
          const firebaseId = userDoc.id;
          setUid(firebaseId);
          setQuestion(userdata.questionText);
          setLoading(false);
          // Fetch the profile image
          getDownloadURL(ref(storage, `avatars/${firebaseId}`))
            .then((url) => {
              setProfileImage(<img className="pfp" alt="user" src={url} id="avatar" />);
            })
            .catch((error) => {
              // Handle any errors that occur during the fetch, or set a default image
              console.error("Error fetching profile image:", error);
            });
        } else {
          alert("Пользователь не найден");
          navigate("/");
          setLoading(false);
        }
      })
      .catch((error) => {
        logEvent(analytics, 'get_doc_failed', {
          userId: userId,
          error: error.message
        });
        console.error("Error getting user document:", error);
        setLoading(false);
        alert("Произошла ошибка при получении данных пользователя");
      });

  }, [userId, navigate]);


  const onSendClick = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const addQuestion = httpsCallable(functions, "addQuestion");

    if (!uid) {
      setLoading(false);
      logEvent(analytics, 'send_message_uid_null', { userId });
      alert("Пользователь не загружен😢\nОбновите страницу \n");
      return;
    }

    addQuestion({ uid, text, userId })
      .then((result) => {
        navigate("/sent");
      })
      .catch((error) => {
        setLoading(false);
        alert("Произошла ошибка при отправке вопроса 😢\nОбновите страницу \n" + error.message);
        console.error("Error adding question: ", error);
      });
  };

  const handleTextChange = (event) => {
    const inputText = event.target.value;
    setText(inputText.slice(0, 320));
  };


  const diceClick = () => {
    const array = [
      "Кто тебе нравится? 👀",
      "Расскажи свой секрет? 🫢",
      "На что потратила бы миллион? 😎",
      "В чем сила?",
      "Я тебя люблю",
      "Какие планы на будущее?",
      "Без тебя душа болела, Ты пришла и всё прошло",
      "Во что ты веришь?",
      "Твоя любимая фотография?",
      "Какое место хотелось бы посетить больше всего?",
      "Ты краш 😍",
      "Самая вкусная еда?",
    ];
    const randomString = array[Math.floor(Math.random() * array.length)];
    setText(randomString);
  };

  return (
    <div className="container">
      <div className="form">
        <div className="bubble">
          <div className="header">
            <div className="pfp-container">
              {/* <img className="pfp" alt="user" id="avatar" /> */}
              {profileImage}
            </div>
            <div className="user-container">
              <div className="username">@{userId}</div>
              <div className="prompt">{question}</div>
            </div>
          </div>
          <div className="textarea-container">
            <div className="dice-button" onClick={diceClick}>
              🎲
            </div>
            <textarea
              placeholder="Отправь мне анонимное сообщение…"
              name="question"
              autoComplete="off"
              id="question"
              value={text}
              onChange={handleTextChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            ></textarea>
          </div>
          <div className="anonymous-tooltip">🔒 100% анонимно и безопасно</div>
          <button
            className="submit"
            onMouseDown={onSendClick}
            style={{
              display: text.length ? "block" : "none",
            }}
          >
            {loading ? <div className="spinner"></div> : "Отправить"}
          </button>
        </div>
        <div
          className="userGet"
          // style={{ display: isFocused ? "none" : "block" }}
        >
          <h2 className="download-prompt">
            👇 <span className="clickCount">5590</span> людей нажали на эту
            кнопку👇{" "}
          </h2>
          <button onClick={ClickDownload} className="submit animate">
            Получай свои вопросы!
          </button>
        </div>
      </div>
    </div>
  );
};

export default User;
