import React, { useState } from "react";
import "./Sent.css";
import left from "./left.png";
import sent from "./sent.png";
import { ClickDownload } from "./ClickDownload";

const Sent = () => {
  return (
    <div className="containersent">
      <div className="navbar">
        <a className="another1" href="javascript:history.back()">
          <img src={left} width="24" height="24" />
        </a>
      </div>
      <div className="space1"></div>
      <img className="check" src={sent} />
      <div className="space2"></div>
      <h1 className="sent">Отправлено!</h1>
      <div className="space4"></div>
      <h2 className="download-prompt">
        {" "}
        👇 <span className="clickCount">3039</span> людей нажали на эту кнопку
        👇{" "}
      </h2>
      <div className="space2"></div>
      {/* <a className="button button-white boost">Поднять свое сообщение! 🚀</a> */}
      {/* <a
        className="button download-link download-link2 animate"
        href="https://apps.apple.com/us/app/ngl-anonymous-q-a/id1596550932?ppid=543cb167-5bdc-448f-a202-e5506f5d2837"
        target="_blank"
        rel="noreferrer"
      >
        Получай свои сообщения!
      </a> */}

      <button onClick={ClickDownload} className="submit animate">
        Получай свои вопросы!
      </button>
      <div className="space2"></div>
      <a className="another another1" href="javascript:history.back()">
        Отправить еще одно сообщение
      </a>
    </div>
  );
};

export default Sent;
