import React, { useState } from "react";
import "./Sent.css";
import appStore from "./appStore.png";
import googlePlay from "./googlePlay.png";

const Download = () => {
  var ua = navigator.userAgent.toLowerCase();
  var url;

  if (ua.indexOf("android") > -1) {
    // Android device so redirect to Google Play
    url =
      "https://play.google.com/store/apps/details?id=com.deniskarpenko.paparazzi";
    window.location.href = url;
  } else if (
    ua.indexOf("iphone") > -1 ||
    ua.indexOf("ipad") > -1 ||
    ua.indexOf("ipod") > -1
  ) {
    // iOS device so redirect to AppStore
    url = "https://apps.apple.com/app/спроси-анонимные-вопросы/id6462873992";
    window.location.href = url;
  } else {
    // Not a mobile device so redirect to your website
    // url = "https://sprosi.link";
  }
  return (
    <div>
      <a
        href="https://apps.apple.com/app/спроси-анонимные-вопросы/id6462873992"
        target="_blank"
        rel="noreferrer"
      >
        <img className="downloadImage appStore" src={appStore} />
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=com.deniskarpenko.paparazzi"
        target="_blank"
        rel="noreferrer"
      >
        <img className="downloadImage googlePlay" src={googlePlay} />
      </a>
    </div>
  );
};

export default Download;
