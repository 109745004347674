import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import User from "./User";
import Sent from "./Sent";
import Home from "./Home";
import Policy from "./Policy";
import Download from "./Download";
import Support from "./Support";
import Terms from "./Terms";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/sent" element={<Sent />} />
        <Route path="/download" element={<Download />} />
        <Route path="/support" element={<Support />} />
        <Route path="/:userId" element={<User />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default App;
