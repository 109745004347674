import React, { useState } from "react";
import "./Home.css";
import imageWebsite from "./imageWebsite.png";
import icon from "./icon.png";
import appStore from "./appStore.png";
import googlePlay from "./googlePlay.png";
import ig from "./ig.png";
import { useParams, useNavigate } from "react-router-dom";
import { ClickDownload } from "./ClickDownload";

const Home = () => {
  let navigate = useNavigate();
  return (
    <div className="containerHome">
      <div className="headerDiv">
        <img className="headerIcon" src={icon}></img>
        <span className="headerText">Sprosi.link</span>
      </div>
      <img className="topImage" src={imageWebsite} loading="lazy" alt=""></img>

      <div className="homeDownload">
        <h2 className="download-prompt">
          👇 <span className="clickCount">5590</span> людей нажали на эту
          кнопку👇{" "}
        </h2>
        <button onClick={ClickDownload} className="submit animate">
          Получай свои вопросы!
        </button>
      </div>

      <div className="section">
        <h2>Описание</h2>
        <div>
          Это свежий взгляд на анонимность. Мы считаем, что анонимность должна
          быть веселым, но безопасным местом для выражения своих чувств и мнений
          без стыда. У молодежи нет пространства для деления своих чувств без
          осуждения со стороны друзей или общественного давления. Спроси
          обеспечивает это безопасное пространство.
        </div>
      </div>

      <div className="section">
        <h2>Безопасность</h2>
        <h3>Анонимность работает только если она безопасна</h3>
        <div>
          Для обеспечения безопасности наших пользователей мы используем AI модерацию контента мирового класса.
          Мы используем глубокое обучение и алгоритмы сопоставления паттернов символов на основе правил для фильтрации вредоносного языка и издевательств.
          Наш алгоритм также может определить семантическое значение эмодзи.
          Это означает, что мы понимаем сленг и мы знаем, как фильтровать вредоносные сообщения.
        </div>
      </div>

      <div className="section">
        <h2>Вопросы</h2>
        <div className="questionAnswer">
          <div className="question">
            - Должен ли я использовать свой реальный инстаграм?
          </div>
          <div className="answer">
            Да, вы должны! Мы не просим ваш пароль или что-либо еще, связанное с
            вашим аккаунтом, так что не стоит беспокоиться.
          </div>
        </div>
        <div className="questionAnswer">
          <div className="question">
            - Я не могу пополнить баланс AppStore, Google Play, что делать?
          </div>
          <div className="answer">
            Нажмите на кнопку ‘помощь с оплатой’ в приложение и наш менеджер
            поможет вам.
          </div>
        </div>
        <div className="questionAnswer">
          <div className="question">- Могу ли я восстановить свой аккаунт?</div>
          <div className="answer">
            Если вы удалите свой аккаунт, мы не сможем его восстановить, так что
            будьте осторожны!
          </div>
        </div>
        <div className="questionAnswer">
          <div className="question">- Могу ли я иметь несколько аккаунтов?</div>
          <div className="answer">
            В настоящее время мы разрешаем только 1 аккаунт на устройство. Если
            вы удалите свой аккаунт или удалите приложение с телефона, вы
            потеряете свои сообщения. Попытка входа в свой аккаунт на новом
            устройстве приведет к созданию другого аккаунта с новой ссылкой и
            входящими сообщениями.
          </div>
        </div>
        <div className="questionAnswer">
          <div className="question">- ПОМОЩЬ! Я не вижу свои сообщения!</div>
          <div className="answer">
            Если вы удалили свой аккаунт или удалили приложение с телефона, вы
            потеряете свои сообщения.
          </div>
        </div>
        <div className="questionAnswer">
          <div className="question">- Как я могу отписаться от Спроси PRO?</div>
          <div className="answer">
            Нам жаль слышать, что вам не нравится подписка на Спроси PRO! Вы
            можете отменить свою подписку в любое время в приложении Настройки,
            если у вас iPhone, или в приложении Google Play, если у вас Android.
          </div>
        </div>
      </div>

      <div className="downloadLinks">
        <a
          href="https://apps.apple.com/app/спроси-анонимные-вопросы/id6462873992"
          target="_blank"
          rel="noreferrer"
        >
          <img className="downloadImage appStore" src={appStore} />
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.deniskarpenko.paparazzi"
          target="_blank"
          rel="noreferrer"
        >
          <img className="downloadImage googlePlay" src={googlePlay} />
        </a>
        <a
          href="https://www.instagram.com/sprosi.link/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="downloadImage ig" src={ig} />
        </a>
      </div>
      <div
        className="policy"
        onClick={() => {
          navigate("/policy");
        }}
      >
        Политика приватности
      </div>
      <div
        className="policy"
        onClick={() => {
          navigate("/terms");
        }}
      >
        Условия использования
      </div>
    </div>
  );
};

export default Home;
